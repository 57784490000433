import {Pretext} from '../_entities/pretext';
import {Card} from '../_entities/card';

export const aboutMe = 'über-mich'
export const impressum = 'impressum'
export const mainPage = '/'
export const offerPage = 'angebot';

export const offerFootCare = 'fußpflege';
export const offerFootCareCards: Card[] = [
	{
		title: 'Med. Fußpflegebehandlung',
		subTitle: 'Standard',
		imagePath: 'assets/Fußpflege.jpg',
		imageAlt: 'Photo of a Shiba Inu',
		priceTable: [
			{name: 'Med. Fußpflegebehandlung', price: 30},
			{name: 'Med. Fußpflegebehandlung mit lackieren', price: 35},
			{name: 'Med. Fußpflegebehandlung mit Shellack', price: 45},
		],
		description: '<i>Shellac</i> ist eine besondere <i>Nagellack</i>-Variante in Gel-Form.<br>' +
			'Nach dem Auftragen wird <i>Shellac</i> mit UV-Licht gehärtet.<br> ' +
			'Einmal Aufgetragen hält <i>Shellac</i> über mehrere Wochen und muss erst dann erneuert werden.<br> ' +
			'Für den Nagel ist <i>Shellac</i> nicht schädlich.'
	},
	{
		title: 'Med. Fußpflegebehandlung',
		subTitle: 'Luxus',
		// imagePath: 'https://material.angular.io/assets/img/examples/shiba2.jpg',
		imageAlt: 'Photo of a Shiba Inu',
		priceTable: [
			{name: 'Med. Fußpflegebehandlung + Fuß-/Beinmassage', price: 45}
		],
		description: 'Die klassische Fuß- und Bein-Massage ist eine äußerst wohltuende und kräftige Massage. Sie entspannt die Muskulatur,' +
			' regt die Durchblutung an und beseitigt das Gefühl "schwerer" Beine.'
	}, {
		title: 'Hühnerauge',
		// imagePath: 'https://material.angular.io/assets/img/examples/shiba2.jpg',
		imageAlt: 'Photo of a Shiba Inu',
		priceTable: [
			{name: 'Behandlung eines Hühnerauges', price: 5}
		],
		description: 'Hühneraugen entstehen, wenn eine Hautstelle über längere Zeit oder immer wieder Druck und Reibung' +
			' ausgesetzt ist. Sie bilden sich vor allem an den Zehen und unter der Fußsohle. Dabei verdickt zunächst die' +
			' Hornschicht und es entsteht eine Schwiele. Lässt der Druck nicht nach, kann sich daraus ein schmerzhaftes' +
			' Hühnerauge entwickeln. '
	}, {
		title: 'Tamponade',
		// imagePath: 'https://material.angular.io/assets/img/examples/shiba2.jpg',
		imageAlt: 'Photo of a Shiba Inu',
		priceTable: [
			{name: 'Tamponade', price: 5}
		],
		description: 'Eine Nagelfalztamponade ist eine Behandlungsmethode um einen <i>eingewachsenen Nageloder</i> ' +
			'auch einen <i>Rollnagel</i> zu behandeln. Bei dieser Methode wird ein dünner Vliesstreifen (Tamponade) unter' +
			' den seitlichen Nagelrand geschoben, so dass die Tamponade zwischen Nagelfalz und Nagel liegt. Dadurch wird' +
			' der seitliche Nagelrand leicht angehoben und der Druck auf das umliegende Gewebe wird genommen. Die Tamponade' +
			' verhindert dass der Zehennagel einwächst und begünstigt das gesunde Vorwachsen des Zehennagels. Wir erklären dir' +
			' in diesem Beitrag welche Hilfsmittel du für diese Behandlungsmethode benötigst und wie du deinen Nagel' +
			' tamponieren kannst. '
	}, {
		title: 'Druckstellen-Bandage',
		// imagePath: 'https://material.angular.io/assets/img/examples/shiba2.jpg',
		imageAlt: 'Photo of a Shiba Inu',
		priceTable: [
			{name: 'Druckstellen-Bandage', price: 6, prePrice: 'ab'}
		],
		description: 'Druckstellen können gemindert werden und verhindern ihr entstehen. ' +
			' Die Bandage stabilisiert den Druck.'
	}, {
		title: 'Beindepilation',
		// imagePath: 'https://material.angular.io/assets/img/examples/shiba2.jpg',
		imageAlt: 'Photo of a Shiba Inu',
		priceTable: [
			{name: 'Beindepilation', price: 45, prePrice: 'ab'}
		],
		description: 'Weil die lang­anhaltende Haarent­fernung mit seidig glatter Haut für bis zu vier Wochen überzeugt und eine' +
			' sehr gründliche Methode ist. Männer und Frauen haben die Möglichkeit schwer zugängliche Körper­zonen wie den' +
			' Rücken, Schultern, Bauch, Brust und Po waxen zu lassen. Was ist nach einer Enthaarung mit Wachs zu beachten? ' +
			'Zwischen den einzelnen Behandlungen sollten keine Enthaarungscremes, Epilationsgeräte oder Rasierklingen benutzt' +
			' werden. Eine leichte Rötung (rote Pünktchen) der Haut ist normal und bildet sich nach einigen Stunden zurück. ' +
			'Heiße Bäder oder Sauna frühestens 24 Stunden nach der Enthaarung. ' +
			'Direkte Sonne oder Solarium frühestens 48 Stunden nach der Enthaarung. ' +
			'Kein Peeling in den nächsten drei Tagen, danach regelmäßig 2 x pro Woche ein mildes Peeling oder abreiben' +
			' mit Luffa-Handschuh, dadurch wird die Verhornung des Follikelausgangs verhindert und beugt so dem Einwachsen' +
			' von Haaren vor. Im Gesicht kann die Haut besonders empfindlich reagieren, daher direkt nach der Depilation auf' +
			' Make-up verzichten'
	}, {
		title: 'Fußreflexzonen-Massage',
		// imagePath: 'https://material.angular.io/assets/img/examples/shiba2.jpg',
		imageAlt: 'Photo of a Shiba Inu',
		priceTable: [
			{name: 'Fußreflexzonen-Massage', price: 30}
		],
		description: 'Diese überaus wohltuende Massage Ihrer Füße hat einen positiven Einfluss sowohl auf die Durchblutung' +
			' und den Stoffwechsel als auch auf die inneren Organe Ihres Körpers.'
	}, {
		title: 'Gutschein',
		imagePath: 'assets/FußPflegeGuschein.webp',
		description: 'Alle Behandlungen können Sie als Gutschein erwerben.'
	}
]
export const offerFootCarePretext: Pretext = {
	h1: 'Fußpflege nach medizinischen Richtlinien',
	h2: 'Jetzt freuen sich eure Füße, wieder aus den dicken Socken und Stiefeln raus zu kommen!',
	text2: 'Nägel kürzen, Nagelhaut pflegen, Hornhaut- und Hühneraugen entfernen, Nägel- und Hornhaut glätten, leichte Massage mit pflegender Fußcreme.<br>Mein Gebiet ist eingewachsene Nägel, Diabetiker Füße, Nagelpilz, Hühneraugen, Schrunden und Warzen.<br>Also alles was an einem Fuß an Krankheiten sein kann. Gerne auch schöne French Nägel für den Sommer oder Shellack,toder einfach nur Lackieren. <br>Ich mache ihre Füße fit und schön.',
	imagePath: 'assets/fußPflegeBild.webp'
}

export const offerAboutMe = ''

export const offerMassage = 'massage'
export const offerMassageCards: Card[] = [
	{
		title: 'Angebote',
		priceTable: [
			{name: 'Rückenmassage ca. 30min', price: 30},
			{name: 'Schulter. und Nackenmassage ca. 30min', price: 30},
			{name: 'Ganzkörpermassage ca. 60min', price: 65},
			{name: 'Heiße Rolle', price: 10},
			{name: 'Fußreflex Massage', price: 30},
			{name: 'Hot-Stone Massage ca. 90min', price: 90},
			{name: 'Ohrenkerze Methode/Ohrenakupressur', price: 30},
		],
	}, {
		title: 'Gutschein',
		imagePath: 'assets/GutscheinAllgemein.webp',
		description: 'Alle Behandlungen können Sie als Gutschein erwerben.'
	}
]
export const offerMassagePretext: Pretext = {
	imagePath: 'assets/MassageBild.webp'
}

export const offerKosmetik = 'Kosmetik'
export const offerKosmetikCards: Card[] = [
	{
		title: 'Basis',
		// imagePath: 'https://material.angular.io/assets/img/examples/shiba2.jpg',
		imageAlt: 'Photo of a Shiba Inu',
		priceTable: [
			{name: 'Basis', price: 60}
		],
		description: 'Reinigung, Peeling mit Frimator, Vopozondampf, Entfernung von Hautunreinheiten, ' +
			'Braunkorrektur, Individuelle Maske, Abschlusspflege'
	},
	{
		title: 'Deluxe',
		// imagePath: 'https://material.angular.io/assets/img/examples/shiba2.jpg',
		imageAlt: 'Photo of a Shiba Inu',
		priceTable: [
			{name: 'Deluxe', price: 80}
		],
		description: 'Reinigung, Peeling mit Frimator, Vopozondampf, Entfernung von' +
			' Hautunreinheiten, Braunkorrektur, ' +
			'Individuelle Maske / Ampulle, Gesicht-Hals-Dekollettemassage, Abschlusspflege'
	},
	{
		title: 'Diamond Mikrodermabrasion',
		// imagePath: 'https://material.angular.io/assets/img/examples/shiba2.jpg',
		imageAlt: 'Photo of a Shiba Inu',
		priceTable: [
			{name: 'Diamond Mikrodermabrasion', price: 60}
		],
		description: 'Die abgestorbenen Hautschichten müssen abgetragen werden, um eine ' +
			'Verbesserung des Hautbildes zu erzielen. Mit der <i>Diamant Mikrodermabrasion</i> wird eine sanfte' +
			' und schmerzfreie Abtragung der oberen Hautschichten vorgenommen. Die natürliche Hautregeneration' +
			' wird dadurch angeregt und das Hautbild wird sichtbar verbesserten. Die <i>Mikrodermabrasion</i>' +
			' bewirkt eine Tiefenreinigung der Haut. Die Tiefenwirkung, durch die Kombination von Peeling ' +
			'und Vakuum, stimuliert die hauteigene Collagen- und Elastinproduktion, wodurch die Haut straffer wird.'
	},
	{
		title: 'Lash Lifting',
		// imagePath: 'https://material.angular.io/assets/img/examples/shiba2.jpg',
		imageAlt: 'Photo of a Shiba Inu',
		priceTable: [
			{name: 'Lash Lifting', price: 60}
		],
		description: 'Das <i>Lash Lifting</i> ist eine spezielle und neu entwickelte Methode um den' +
			' Naturwimpern einen dauerhaft starken Schwung nach oben zu verleihen. Anders als bei der' +
			' herkömmlichen Wimperdauerwelle werden die Wimpern nicht nur gewellt, sondern direkt und gezielt' +
			' nach oben geliftet.'
	},
	{
		title: 'Weitere Behandlungen',
		// imagePath: 'https://material.angular.io/assets/img/examples/shiba2.jpg',
		imageAlt: 'Photo of a Shiba Inu',
		priceTable: [
			{name: 'Wimpern färben', price: 8},
			{name: 'Augenbrauen Färben', price: 7},
			{name: 'Augenbraunenkorrektur', price: 5},
			{name: 'Gesichtsenthaarung', price: 10, prePrice: 'ab'},
			{name: 'Warzen/ Stielwarzen/Fibrome-entfernung (mit Plasma Pen)', price: 10, prePrice: 'ab'}
		]
	}, {
		title: 'Gutschein',
		imagePath: 'assets/GutscheinAllgemein.webp',
		description: 'Alle Behandlungen können Sie als Gutschein erwerben.'
	}
]
export const offerKosmetikPretext: Pretext = {
	h1: 'Kosmetische Gesichtsbehandlung',
	text1: 'Eine professionelle Gesichtsbehandlung bei einer Kosmetikerin kann dabei helfen, dass sich dein Hautbild mit der\
	Zeit sichtbar verbessert. Bereits nach dem ersten Besuch im Kosmetikstudio sind deutliche Ergebnisse sichtbar und\
	deine Haut erstrahlt in einem neuen Glanz. Dabei ist es wichtig, dass die Art der Behandlung auf deinen Hauttyp\
	abgestimmt wird. Wir stellen dir sechs kosmetische Gesichtsbehandlungen vor, die gegen Akne, Couperose,\
	Pigmentstörungen und erste Falten helfen.',
	h2: 'Wie läuft eine klassische Gesichtsbehandlung ab?',
	text2: 'Bei der kosmetischen Gesichtsbehandlung werden zunächst mit passenden Reinigungsprodukten die Augen und die Haut von\
	Make-Up und Hautlagerungen befreit. Anschließend wird die Haut durch ein Peeling gründlich gereinigt. In einigen\
	Fällen kommen nun Dampf oder warme Kompressen zum Einsatz, um die Poren zu öffnen. Danach wird die Haut\
	ausgereinigt.\
	Bei diesem Behandlungsschritt werden die Hautunreinheiten im Gesicht und am Dekolletee schonend ausgedrückt.\
	Anschließend wird eine pflegende Maske auf Gesicht, Hals und Dekolletee aufgetragen, um die Haut zu beruhigen. Das\
	Highlight der Behandlung bei der Kosmetikerin ist die wohltuende und entspannende Gesichtsmassage.\
	Es ist wichtig, dass du die kosmetische Gesichtsbehandlung regelmäßig durchführen lässt. Ein Zeitraum von vier\
	Wochen ist sinnvoll, da die Haut in der Regel einen Monat Zeit benötigt, um sich zu regenerieren. Nach einiger Zeit\
	kannst du mit einer verbesserten Hautgesundheit rechnen.\
	Denn durch die Gesichtsbehandlung wird unter anderem der Blutfluss in der Haut verbessert. Dadurch gelangen mehr\
	Nährstoffe und Sauerstoff in deine Hautzellen. Dies sorgt dafür, dass sich deine Haut entspannt sowie Fältchen\
	und kleine Linien effektiv geglättet werden. Das Ergebnis einer regelmäßigen Gesichtsbehandlung ist eine rosigere,\
	strahlendere und gesündere Haut.',
	imagePath: 'assets/kosmetikBild.webp'
}

export const offerManikuere = 'Maniküre'
export const offerManikureCards: Card[] = [
	{
		title: 'Klassische Maniküre',
		// imagePath: 'https://material.angular.io/assets/img/examples/shiba2.jpg',
		imageAlt: 'Photo of a Shiba Inu',
		priceTable: [
			{name: 'Klassische Maniküre', price: 25}
		]
	},
	{
		title: 'Maniküre Luxus',
		subTitle: 'klassische Maniküre mit Paraffinbad',
		// imagePath: 'https://material.angular.io/assets/img/examples/shiba2.jpg',
		imageAlt: 'Photo of a Shiba Inu',
		priceTable: [
			{name: 'Maniküre Luxus', price: 48}
		],
		description: 'Aromatherapie Paraffinwachs/ Paraffinbad ist Wellness ' +
			'pur für Ihre Hände . Spendet trockener Haut Feuchtigkeit und macht sie' +
			' geschmeidig. Schafft zugleich Abhilfe bei steifen Gelenken und' +
			' Muskelmüdigkeit. Bei der Paraffinbehandlung werden die Hände in' +
			' einen speziellen Paraffinerwärmer gelegt. '
	},
	{
		title: 'Parafinbad',
		// imagePath: 'https://material.angular.io/assets/img/examples/shiba2.jpg',
		imageAlt: 'Photo of a Shiba Inu',
		priceTable: [
			{name: 'Parafinbad', price: 25}
		],
	},
	{
		title: 'Nägel Lackierung',
		// imagePath: 'https://material.angular.io/assets/img/examples/shiba2.jpg',
		imageAlt: 'Photo of a Shiba Inu',
		priceTable: [
			{name: 'normaler Lack', price: 5},
			{name: 'mit Shellac', price: 15}
		],
		description: '<i>Shellac</i> ist eine besondere Nagellack-Variante in Gel-Form. ' +
			'Nach dem Auftragen wird <i>Shellac</i> mit UV-Licht gehärtet. Einmal Aufgetragen' +
			' hält <i>Shellac</i> über mehrere Wochen und muss erst dann erneuert werden. ' +
			'Für den Nagel ist <i>Shellac</i> nicht schädlich.'

	}, {
		title: 'Gutschein',
		imagePath: 'assets/GutscheinAllgemein.webp',
		description: 'Alle Behandlungen können Sie als Gutschein erwerben.'
	}
]
export const offerManikurePretext: Pretext = {
	h1: 'Manikür für Damen und Herren',
	text1: 'Bei der Maniküre werden die Nägel gepfeilt, geformt und gekürzt. Dabei wird auf den Wunsch der Kunden geachtet, wie\
	Sie ihre Nagelform und ihre Nagellänge gerne hätte. Die Nagelhaut wird zurück geschoben oder nach wünsch entfernt.',
	imagePath: 'assets/maniküreBild.webp'
}
export const offerAboutMePretext: Pretext = {
	h1: 'Über Uns',
	text1: 'Herzlich willkommen auf unserer Seite! <br>' +
		'Ich bin Irina Lisow, stolze Inhaberin von Studio-Lissa, und ich betreue unsere geschätzten Kunden gemeinsam mit meiner Tochter Emelie. <br>' +
		'Mit Leidenschaft und Hingabe widmen wir uns der Kunst der Schönheitspflege und Entspannung. <br>' +
		'Unsere Qualifikationen erstrecken sich über eine umfassende Ausbildung in Fußpflege, Kosmetik und Massage. <br>' +
		'Mit einem fundierten Fachwissen und langjähriger Erfahrung in der Branche setzen wir alles daran, Ihren Bedürfnissen gerecht zu werden. <br>' +
		'Kompetenz ist für uns nicht nur ein Wort, sondern eine Selbstverpflichtung. <br>' +
		'Durch regelmäßige Weiterbildungen halten wir unser Wissen stets auf dem neuesten Stand, um Ihnen innovative und effektive Behandlungen bieten zu können. <br>' +
		'Ihr Vertrauen ist unsere Motivation, und wir nehmen uns die Zeit, um auf Ihre individuellen Anliegen einzugehen. <br>' +
		'In unserem Studio erwartet Sie nicht nur eine qualifizierte und kompetente Betreuung, sondern auch eine professionelle Atmosphäre, die zum Wohlfühlen einlädt. <br>' +
		'Jeder Besuch soll für Sie zu einem rundum erholsamen Erlebnis werden. <br>' +
		'Unser gemeinsames Engagement für Ihre Schönheit und Entspannung ist unsere Berufung. <br>' +
		'Wir freuen uns darauf, Sie persönlich kennenzulernen und gemeinsam mit Ihnen den Weg zu Ihrem Wohlbefinden zu gestalten. <br>' +
		'Herzlichst,<br>' +
		'Irina und Emelie'
}

export const offerPflegeprodukte = 'Pflegeprodukte'
export const offerPflegeprodukteCards: Card[] = []
export const offerPflegeproduktePretext: Pretext = {}
