import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MenuComponent} from './menu/menu.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';
import {MainPageComponent} from './main-page/main-page.component';
import {ContactComponent} from './contact/contact.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {FooterComponent} from './footer/footer.component';
import {ImpressumComponent} from './impressum/impressum.component';
import {CardComponent} from './_shared/card/card.component';
import {PageOfferComponent} from './page-offer/page-offer.component';
import {PretextComponent} from './_shared/pretext/pretext.component';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from "@angular/material/menu";
import {MatCardModule} from "@angular/material/card";
import {MatTableModule} from "@angular/material/table";

@NgModule({
	declarations: [
		AppComponent,
		MenuComponent,
		MainPageComponent,
		ContactComponent,
		FooterComponent,
		ImpressumComponent,
		CardComponent,
		PageOfferComponent,
		PretextComponent,
	],

	imports: [BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		MatMenuModule,
		MatIconModule,
		MatButtonModule,
		MatToolbarModule,
		AngularSvgIconModule.forRoot(),
		MatCardModule,
		MatTableModule,
	],
	providers: [provideHttpClient(withInterceptorsFromDi())],
	bootstrap: [AppComponent],
})
export class AppModule {
}
