import {Component, HostListener, OnInit} from '@angular/core';
import {environment} from '../environments/environment.prod';
import {NavigationEnd, Router} from '@angular/router';

// tslint:disable-next-line:ban-types
declare let gtag: Function;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'lissa';
	cookieMessage = 'Diese Seite verwendet Cookies, um Ihnen ein bestmöglichstes Erlebnis zu ermöglichen.';
	cookieDismiss = 'Verstanden';
	cookieLinkText = 'Datenschutzrichtlinie';
	mobileContent: boolean

	constructor(
		public router: Router,
	) {
		this.analytics();
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.isMobileContent()
	}

	//  todo cookie hinweis animation entfernen
	ngOnInit(): void {
		this.isMobileContent()
		const cc = window as any;
		cc.cookieconsent.initialise({
			palette: {
				popup: {
					background: '#164969'
				},
				button: {
					background: '#ffe000',
					text: '#164969'
				}
			},
			theme: 'classic',
			content: {
				message: this.cookieMessage,
				dismiss: this.cookieDismiss,
				link: this.cookieLinkText,
				href: 'impressum'
			}
		});
	}

	analytics() {
		if (environment.production) {
			this.router.events.subscribe(event => {
					if (event instanceof NavigationEnd) {
						gtag('config', 'G-C7NTNLDLZB',
							{
								page_path: event.urlAfterRedirects,
								title: event.urlAfterRedirects,
							}
						);
					}
				}
			);
		}
	}

	isMobileContent() {
		const mobileSize = 450;
		this.mobileContent = window.innerWidth < mobileSize;
	}
}
