import {Injectable} from '@angular/core';
import {Contact} from '../_entities/contact';
import {Observable, of} from 'rxjs';
import {Address} from '../_entities/adress';

@Injectable({
	providedIn: 'root'
})
export class ContactService {

	addressIrina: Address = {
		street: 'Allensteinerstr. 18',
		city: 'Waldbröl',
		country: 'Deutschland',
		mail: 'info@studio-lissa.de',
		plz: 51545,
		business: 'Studio Lissa'
	}

	irina: Contact = {
		name: 'Irina Lisow',
		phoneInternational: '+4915234210871',
		phoneInternationalSimple: '4915234210871',
		phoneNational: '015234210871',
	};

	emi: Contact = {
		name: 'Emelie Lisow',
		phoneInternational: '+4917655281384',
		phoneInternationalSimple: '4917655281384',
		phoneNational: '017655281384',
	};

	getContactIrina(): Observable<Contact> {
		return of(this.irina);
	}

	getContactEmi(): Observable<Contact> {
		return of(this.emi);
	}
	getAddress(): Observable<Address> {
		return of(this.addressIrina);
	}
}
