import {Component, OnInit} from '@angular/core';
import {Contact} from '../_entities/contact';
import {ContactService} from '../_services/contact.service';
import {Address} from '../_entities/adress';

@Component({
	selector: 'app-impressum',
	templateUrl: './impressum.component.html',
	styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent implements OnInit {

	contact: Contact;
	address: Address

	constructor(
		private contactService: ContactService
	) {
	}

	ngOnInit(): void {
		this.contactService.getContactIrina().subscribe(contact => this.contact = contact);
		this.contactService.getAddress().subscribe(contact => this.address = contact);
	}

}
