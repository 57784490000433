<br>
<h2>Impressum</h2>
<p id="Seiteninhaber">
	<br/>
	<span>Seiteninhaber:</span>
	<br/>
	<span>{{contact.name}}</span>
	<br/>
	<span>{{address.street}}</span>
	<br/>
	<span>{{address.plz}} {{address.city}}</span>
	<br/>
	<span>Deutschland</span>
</p>

<p id="Kontakt">
	<br/>
	<span>Kontakt:</span>
	<br/>
	<span>Mail: {{address.mail}}</span>
	<br/>
	<span>Telefon: {{contact.phoneInternational}}</span>
</p>

<div id="copyright-flaticon" class="impressum-space">
	Icons made by <a href="http://www.freepik.com/" title="Freepik">Freepik</a> from <a
	href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
</div>

<div id="Datenschutzerklärung" class="impressum-space impressum-border dsgvo-padding">
	<div class="s3-t s3-t0">
		<p><strong>Datenschutzerkl&auml;rung</strong></p>
		<p><strong>Allgemeiner Hinweis und Pflichtinformationen</strong></p>
		<p><strong>Benennung der verantwortlichen Stelle</strong></p>
		<p>Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser Website ist:</p>
		<p><span id="s3-t-firma">{{address.business}}</span><br/><span
			id="s3-t-ansprechpartner">{{contact.name}}</span><br/><span
			id="s3-t-strasse">{{address.street}}</span><br/><span
			id="s3-t-ort">{{address.city}}</span>
		</p>
		<p>
			Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel
			der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. &Auml;.).
		</p>
		<p><strong>Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong></p>
		<p>Nur mit Ihrer ausdr&uuml;cklichen Einwilligung sind einige Vorg&auml;nge der
			Datenverarbeitung m&ouml;glich.
			Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit m&ouml;glich. F&uuml;r den Widerruf gen&uuml;gt
			eine formlose Mitteilung per E-Mail. Die Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten
			Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.</p>
		<p><strong>Recht auf Beschwerde bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde</strong></p>
		<p>
			Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Versto&szlig;es ein Beschwerderecht
			bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde zu. Zust&auml;ndige Aufsichtsbeh&ouml;rde bez&uuml;glich
			datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der
			Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten
			sowie deren Kontaktdaten bereit:&nbsp
			<a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
			   target="_blank">
				https://www.bfdi.bund.de/
			</a>.
		</p>
		<p><strong>Recht auf Daten&uuml;bertragbarkeit</strong></p>
		<p>Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erf&uuml;llung eines
			Vertrags automatisiert verarbeiten, an sich oder an Dritte aush&auml;ndigen zu lassen. Die
			Bereitstellung
			erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte &Uuml;bertragung der Daten an einen
			anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
		<p><strong>Recht auf Auskunft, Berichtigung, Sperrung, L&ouml;schung</strong></p>
		<p>Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche
			Auskunft
			&uuml;ber Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empf&auml;nger und den
			Zweck
			der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder L&ouml;schung dieser Daten.
			Diesbez&uuml;glich
			und auch zu weiteren Fragen zum Thema personenbezogene Daten k&ouml;nnen Sie sich jederzeit &uuml;ber
			die im
			Impressum aufgef&uuml;hrten Kontaktm&ouml;glichkeiten an uns wenden.</p>
		<p><strong>SSL- bzw. TLS-Verschl&uuml;sselung</strong></p>
		<p>Aus Sicherheitsgr&uuml;nden und zum Schutz der &Uuml;bertragung vertraulicher Inhalte, die Sie an uns als
			Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschl&uuml;sselung. Damit sind Daten,
			die
			Sie &uuml;ber diese Website &uuml;bermitteln, f&uuml;r Dritte nicht mitlesbar. Sie erkennen eine verschl&uuml;sselte
			Verbindung an der &bdquo;https://&ldquo; Adresszeile Ihres Browsers und am Schloss-Symbol in der
			Browserzeile.</p>
	</div>
	<div class="s3-t s3-t12">
		<p><strong>Cookies</strong></p>
		<p>Unsere Website verwendet Cookies. Das sind kleine Textdateien, die Ihr Webbrowser auf Ihrem Endger&auml;t
			speichert. Cookies helfen uns dabei, unser Angebot nutzerfreundlicher, effektiver und sicherer zu
			machen.</p>
		<p>Einige Cookies sind &ldquo;Session-Cookies.&rdquo; Solche Cookies werden nach Ende Ihrer Browser-Sitzung
			von
			selbst gel&ouml;scht. Hingegen bleiben andere Cookies auf Ihrem Endger&auml;t bestehen, bis Sie diese
			selbst
			l&ouml;schen. Solche Cookies helfen uns, Sie bei R&uuml;ckkehr auf unserer Website wiederzuerkennen.</p>
		<p>Mit einem modernen Webbrowser k&ouml;nnen Sie das Setzen von Cookies &uuml;berwachen, einschr&auml;nken
			oder
			unterbinden. Viele Webbrowser lassen sich so konfigurieren, dass Cookies mit dem Schlie&szlig;en des
			Programms von selbst gel&ouml;scht werden. Die Deaktivierung von Cookies kann eine eingeschr&auml;nkte
			Funktionalit&auml;t unserer Website zur Folge haben.</p>
		<p>Das Setzen von Cookies, die zur Aus&uuml;bung elektronischer Kommunikationsvorg&auml;nge oder der
			Bereitstellung bestimmter, von Ihnen erw&uuml;nschter Funktionen (z.B. Warenkorb) notwendig sind,
			erfolgt
			auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes
			Interesse an der Speicherung von Cookies zur technisch fehlerfreien und reibungslosen Bereitstellung
			unserer
			Dienste. Sofern die Setzung anderer Cookies (z.B. f&uuml;r Analyse-Funktionen) erfolgt, werden diese in
			dieser Datenschutzerkl&auml;rung separat behandelt.</p>
	</div>
	<div class="s3-t s3-t13">
		<p><strong>Google Analytics</strong></p>
		<p>Unsere Website verwendet Funktionen des Webanalysedienstes Google Analytics. Anbieter des
			Webanalysedienstes
			ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.</p>
		<p>Google Analytics verwendet "Cookies." Das sind kleine Textdateien, die Ihr Webbrowser auf Ihrem Endger&auml;t
			speichert und eine Analyse der Website-Benutzung erm&ouml;glichen. Mittels Cookie erzeugte Informationen
			&uuml;ber Ihre Benutzung unserer Website werden an einen Server von Google &uuml;bermittelt und dort
			gespeichert. Server-Standort ist im Regelfall die USA.</p>
		<p>Das Setzen von Google-Analytics-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als
			Betreiber
			dieser Website haben wir ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um unser
			Webangebot und ggf. auch Werbung zu optimieren.</p>
		<p>IP-Anonymisierung</p>
		<p>Wir setzen Google Analytics in Verbindung mit der Funktion IP-Anonymisierung ein. Sie gew&auml;hrleistet,
			dass Google Ihre IP-Adresse innerhalb von Mitgliedstaaten der Europ&auml;ischen Union oder in anderen
			Vertragsstaaten des Abkommens &uuml;ber den Europ&auml;ischen Wirtschaftsraum vor der &Uuml;bermittlung
			in
			die USA k&uuml;rzt. Es kann Ausnahmef&auml;lle geben, in denen Google die volle IP-Adresse an einen
			Server
			in den USA &uuml;bertr&auml;gt und dort k&uuml;rzt. In unserem Auftrag wird Google diese Informationen
			benutzen, um Ihre Nutzung der Website auszuwerten, um Reports &uuml;ber Websiteaktivit&auml;ten zu
			erstellen
			und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegen&uuml;ber
			uns
			zu erbringen. Es findet keine Zusammenf&uuml;hrung der von Google Analytics &uuml;bermittelten
			IP-Adresse
			mit anderen Daten von Google statt.</p>
		<p>Browser Plugin</p>
		<p>Das Setzen von Cookies durch Ihren Webbrowser ist verhinderbar. Einige Funktionen unserer Website k&ouml;nnten
			dadurch jedoch eingeschr&auml;nkt werden. Ebenso k&ouml;nnen Sie die Erfassung von Daten bez&uuml;glich
			Ihrer Website-Nutzung einschlie&szlig;lich Ihrer IP-Adresse mitsamt anschlie&szlig;ender Verarbeitung
			durch
			Google unterbinden. Dies ist m&ouml;glich, indem Sie das &uuml;ber folgenden Link erreichbare
			Browser-Plugin
			herunterladen und installieren:<br><a href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a>.
		</p>
		<p>Widerspruch gegen die Datenerfassung</p>
		<p>Sie k&ouml;nnen die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link
			klicken. Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zuk&uuml;nftigen Besuchen
			unserer Website verhindert: Google Analytics deaktivieren.</p>
		<p>Einzelheiten zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerkl&auml;rung
			von
			Google:&nbsp<br><a href="https://support.google.com/analytics/answer/6004245?hl=de">https://support.google.com/.../6004245?hl=de</a>.
		</p>
		<p>Auftragsverarbeitung</p>
		<p>Zur vollst&auml;ndigen Erf&uuml;llung der gesetzlichen Datenschutzvorgaben haben wir mit Google einen
			Vertrag
			&uuml;ber die Auftragsverarbeitung abgeschlossen.</p>
		<p>Demografische Merkmale bei Google Analytics</p>
		<p>Unsere Website verwendet die Funktion &ldquo;demografische Merkmale&rdquo; von Google Analytics. Mit ihr
			lassen sich Berichte erstellen, die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher
			enthalten. Diese Daten stammen aus interessenbezogener Werbung von Google sowie aus Besucherdaten von
			Drittanbietern. Eine Zuordnung der Daten zu einer bestimmten Person ist nicht m&ouml;glich. Sie k&ouml;nnen
			diese Funktion jederzeit deaktivieren. Dies ist &uuml;ber die Anzeigeneinstellungen in Ihrem
			Google-Konto m&ouml;glich
			oder indem Sie die Erfassung Ihrer Daten durch Google Analytics, wie im Punkt &ldquo;Widerspruch gegen
			die
			Datenerfassung&rdquo; erl&auml;utert, generell untersagen.</p>
	</div>
	<div class="s3-t s3-t24">
		<p><strong>Google Web Fonts</strong></p>
		<p>Unsere Website verwendet Web Fonts von Google. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway,
			Mountain View, CA 94043, USA.</p>
		<p>Durch den Einsatz dieser Web Fonts wird es m&ouml;glich Ihnen die von uns gew&uuml;nschte Darstellung
			unserer
			Website zu pr&auml;sentieren, unabh&auml;ngig davon welche Schriften Ihnen lokal zur Verf&uuml;gung
			stehen.
			Dies erfolgt &uuml;ber den Abruf der Google Web Fonts von einem Server von Google in den USA und der
			damit
			verbundenen Weitergabe Ihre Daten an Google. Dabei handelt es sich um Ihre IP-Adresse und welche Seite
			Sie
			bei uns besucht haben. Der Einsatz von Google Web Fonts erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
			DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes Interesse an der optimalen Darstellung
			und
			&Uuml;bertragung unseres Webauftritts.</p>
		<p>Das Unternehmen Google ist f&uuml;r das us-europ&auml;ische Datenschutz&uuml;bereinkommen "Privacy
			Shield"
			zertifiziert. Dieses Datenschutz&uuml;bereinkommen soll die Einhaltung des in der EU geltenden
			Datenschutzniveaus gew&auml;hrleisten.</p>
		<p>Einzelheiten &uuml;ber Google Web Fonts finden Sie unter:<br><a
			href="https://www.google.com/fonts#AboutPlace:about">https://www.google.com/fonts</a>
			<br>und
			weitere Informationen in den Datenschutzbestimmungen von Google:<br>
			<a href="https://policies.google.com/privacy/partners?hl=de">https://policies.google.com/.../partners</a>
		</p>
	</div>
	<div class="s3-t s3-t0">
		<p><small>Quelle: Datenschutz-Konfigurator von<br>
			<a href="http://www.mein-datenschutzbeauftragter.de/" target="_blank" rel="noopener">
				mein-datenschutzbeauftragter.de
			</a></small>
		</p>
	</div>
</div>


