import {Component, Input} from '@angular/core';
import {Pretext} from '../../_entities/pretext';

@Component({
	selector: 'app-pretext',
	templateUrl: './pretext.component.html',
	styleUrls: ['./pretext.component.scss']
})
export class PretextComponent{
	@Input() pretext: Pretext
}
