<br>
<h1>{{pretext.h1}}</h1>
<br>
<img *ngIf="pretext.imagePath" src="{{pretext.imagePath}}" alt="" class="image">
<ng-container *ngIf="pretext.text1">
	<p [innerHTML]="pretext.text1"></p>
	<br>
</ng-container>
<ng-container *ngIf="pretext.text2">
	<h2>{{pretext.h2}}</h2>
	<p [innerHTML]="pretext.text2"></p>
	<br>
</ng-container>

