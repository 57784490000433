<mat-toolbar>
	<mat-toolbar-row id="menuButtonRow" class="vertical-box centered">
		<span id="brand" [routerLink]="mainPage">
			<span *ngIf="!mobileContent" class="brandFont">Studio LiSSa</span>
			<span *ngIf="mobileContent" class="brandFontMobile">Studio LiSSa</span>
		</span>
		<ng-container *ngIf="!mobileContent">
				<span>
					<button mat-button [routerLink]="mainPage" class="whiteButton">Startseite</button>
					<button mat-button [routerLink]="offerPage+'/'+offerFootCare" class="whiteButton">Fußpflege</button>
					<button mat-button [routerLink]="offerPage+'/'+offerMassage" class="whiteButton">Massage</button>
					<button mat-button [routerLink]="offerPage+'/'+offerKosmetik" class="whiteButton">Kosmetik</button>
					<button mat-button [routerLink]="offerPage+'/'+offerManikuere" class="whiteButton">Maniküre</button>
					<button mat-button [routerLink]="offerPage+'/'+aboutMe" class="whiteButton">Über mich</button>
				</span>
		</ng-container>
		<span *ngIf="mobileContent" id="smartphoneMenuRow">
			<img [matMenuTriggerFor]="menu" src="assets/burger.svg" alt="smartphoneMenuIcon" id="smartphoneMenuIcon">
		</span>
		<span id="goldRow"></span>
		<mat-menu #menu="matMenu">
			<button mat-menu-item [routerLink]="mainPage">Startseite</button>
			<button mat-menu-item [routerLink]="offerPage+'/'+offerFootCare">Fußpflege</button>
			<button mat-menu-item [routerLink]="offerPage+'/'+offerMassage">Massage</button>
			<button mat-menu-item [routerLink]="offerPage+'/'+offerKosmetik">Kosmetik</button>
			<button mat-menu-item [routerLink]="offerPage+'/'+offerManikuere">Maniküre</button>
			<button mat-menu-item [routerLink]="offerPage+'/'+aboutMe">Über mich</button>
		</mat-menu>
	</mat-toolbar-row>
</mat-toolbar>

