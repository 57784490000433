<mat-card class="card">
	<mat-card-header>
		<mat-card-title>{{card.title}}</mat-card-title>
		<mat-card-subtitle>{{card.subTitle}}</mat-card-subtitle>
	</mat-card-header>
	<div *ngIf="card.imagePath" mat-card-image style="text-align: center;">
		<img  [src]="card.imagePath" [alt]="card.imageAlt" class="image">
	</div>
	<mat-card-content>
		<table *ngIf="card.priceTable" mat-table [dataSource]="card.priceTable" class="myShadow">

			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef></th>
				<td mat-cell *matCellDef="let priceTable"> {{priceTable.name}} </td>
			</ng-container>

			<ng-container matColumnDef="price">
				<th mat-header-cell *matHeaderCellDef> Preis</th>
				<td mat-cell *matCellDef="let priceTable">{{priceTable.prePrice}} {{priceTable.price}} &euro;</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

		</table>
		<p [innerHTML]="card.description">
		</p>
	</mat-card-content>
</mat-card>

