<div class="footer-outer">
	<div class="footer-inner">
		<div>
			<button mat-button [routerLink]="impressum"  class="whiteButton">Impressum</button>
		</div>
		<div>
			<button mat-button routerLink="kontakt"  class="whiteButton">Kontakt</button>
			<ng-container *ngIf="!mobileContent">
				<a href="tel:{{contact.phoneNational}}">
					<button mat-button class="whiteButton">{{contact.phoneNational}}</button>
				</a>
				<a href="mailto:{{address.mail}}?subject=Kontakt%20Anfrage">
					<button mat-button  class="whiteButton">{{address.mail}}</button>
				</a>
				<a href="https://goo.gl/maps/ppRF8dVRXbFUQXSSA" target="_blank">
					<button mat-button  class="whiteButton">{{address.street}} in {{address.plz}} {{address.city}}
					</button>
				</a>
			</ng-container>
		</div>
	</div>
</div>
