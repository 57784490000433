import {Component, Input, OnInit} from '@angular/core';
import {Contact} from '../_entities/contact';
import {ContactService} from '../_services/contact.service';
import {impressum} from '../_shared/const'
import {Address} from '../_entities/adress';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	@Input() mobileContent: boolean
	contact: Contact;
	address: Address
	impressum = impressum
	constructor(private contactService: ContactService) {
	}

	ngOnInit(): void {
		this.contactService.getContactIrina().subscribe(contact => this.contact = contact);
		this.contactService.getAddress().subscribe(contact => this.address = contact);
	}
}
